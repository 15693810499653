@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500&display=swap');

.cover-hero {
	.h-100 {
		.row {
			.col-sm-8 {
				h1 {
					color: $body-color;
					font-weight: 100;
				}
				.content {
					color: $body-color;
				}
				.btn-cta {
					color: #fff;
					border-color: #DB5B1A;
					background-color: #DB5B1A;
					&:hover {
						background-color: #C04F2A;
					}
				}
				.rounded-pill {
					border-radius: 0px !important;
				}
			}
		}
	}
}

// .btn-cta {
// 	font-size: 14px;
// }

// .navbar {
// 	background-color: #FFF;
// }

// .navbar.background-color-primary {
// 	.nav-link {
// 		color: $primary;
// 		&:hover, &:focus, &:active {
// 			color: $secondary;
// 		}
// 		&:visited {
// 			color: $primary;
// 		}
// 	}
// }


#navbarSupportedContent {
	ul {
		.background-color-primary {
			background-color: #fff;
			color: #333;
		}
		.dropdown-item {
			background-color: #fff;
			color: #333;
			&:hover {
				color: #4d148c;
			}
		}
	}
}

.home-how-it-works {
	.btn-link {
		color: #007AB7;
	}
}

.footer-primarybackgroundsection {
	.background-color-primary-lighter {
		background-color: $primary;
		color: #fff;
	}
}

	.btn-cta {
		color: #fff;
		border-color: #DB5B1A;
		background-color: #DB5B1A;
		&:hover {
			background-color: #C04F2A;
		color: #fff;

		}
	}
	.rounded-pill {
		border-radius: 0px !important;
	}

	.fa-circle {
		color: #4d148c;
	}
	.long-arrow-right {
		color: #007AB7;
	}


.design-contact-us-button {
	border-radius: 0px;
}

.container {
	 .btn-default {
		color:#FFF;
		background-color: #DB5B1A;
						&:hover {
							background-color: #C04F2A;
						}
		border-radius: 0px !important;
	}
}

.nav-pills .nav-link {
    background: none;
	color: #333;
    border: 0;
    border-radius: 0rem;
}

.custom-footer-link {
	color: #fff;
	&:hover {
		color: #fff;
	}
}

.footer-link-section {
	background-color: $primary;
	color: #fff;
}

.footer-link-section a {
	color: #fff !important;
}
